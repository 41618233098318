import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Img from "gatsby-image"
import AdsCard from "../components/adsCard"

import { makeStyles } from '@material-ui/core/styles'
import { createGlobalStyle } from "styled-components"

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import BookIcon from '@material-ui/icons/Book'

const AboutPage = ({ data, location }) => {
  const classes = useStyles()

  return (
    <Layout location={location} title="Work">
      <GlobalStyle />
      <SEO
        title={`About`}
        slug="/about/"
      />
      <Container maxWidth="md" >
        <div className={classes.root}>
          <h1 className={classes.title}>
            <BookIcon className={classes.icon}/><span className={classes.about}>About</span>
          </h1>
          <Img
            fluid={data.coverImage.childImageSharp.fluid}
            className={classes.coverImg}
            objectFit="cover"
            alt=""
            />
          <AdsCard slot="9009456012" />
          <div id="about">
            <Typography variant="h5" component="h1">STAY FREE</Typography>
            <Typography variant="body1" component="p">
              「自由に生きていこう」<br />
               「自由のままでいよう」<br />

              エンジニアとして、この夢、目標を達成したいと思っていますが、

              今の時代、何をするにも、どんな事業をするにもITは必須です。
              つまりエンジニアの時代だと思ってます。
              それなのに、企業ではエンジニアはただモノを作るためのコマ扱いされていたり、
              厳しいスケジュールを強いられていたりして
              辛い思いをさせられている方もいると思います。少なくとも僕がそうでした。

              僕はそんなエンジニアの印象を変えたいし、エンジニアがもっと発信し、
              エンジニア主体で何かをすべきだと思いました。

              だから、脱サラ・移住・開業・法人成り（起業）し、「ゲストハウス２軒・焼肉店・カフェ」を運営し
              これらを通じて主体となり発信し、エンジニアがもっと幸せになるべきだと考えています。

              この夢を実現するために、日々の努力や、事業のことや、ITエンジニアとして開発した技術内容などを紹介しています。
            </Typography>
            <Typography variant="h5" component="h1">WRITTEN BY</Typography>
            <Typography variant="body1" component="p">
              うかい｜エンジニア出身CEO/ 株式会社UKAI 代表取締役CEO。<br />
              大学時代は建築を専攻していたが、新卒で未経験でYahoo! Japanにエンジニアとして入社。その後、数回の転職、脱サラし、フリーランスエンジニアを経て、田舎へ移住。ゲストハウスを開業しつつエンジニア業を続ける。その後、焼肉店の開業と同時に、法人成り。現在は、ゲストハウス２軒、焼肉店、カフェ、食品製造業を運営しています。が、2020年コロナウイルスの影響で、ゲストハウスとカフェを閉店。現在は、ECサイト新規リリース、運営と、黒毛和牛の牝牛ブランディングをしメディア立ち上げ。牝牛のお肉と、独自食品開発した食品をまもなく販売開始予定
            </Typography>
            <Typography variant="h6" component="h2">FAVORITE</Typography>
            <Typography variant="body1" component="p">
              サーフィン / 写真 / プログラミング / 焼肉 / 生牡蠣 / ラーメン二郎 / お酒 /
            </Typography>
          </div>
        </div>
        <AdsCard slot="5723982973" />
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    coverImage: file(absolutePath: { regex: "/about.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
const GlobalStyle = createGlobalStyle`
  #about {
    h1,h2,h3,h4,h5,h6 {
      border-left: 0.42813rem solid #00b0ff;
      padding-left: 1.42188rem;
      color: #00b0ff;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    a {
      box-shadow: 0 1px 0 0 currentColor;
      color: #007acc;
      text-decoration: none;
    }
    mark, ins {
      background: #00b0ff
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(10),
  },
  title: {
    textAlign: `center`,
  },
  coverImg: {
    height: `430px`,
    marginBottom: theme.spacing(5),
  },
  about: {
    verticalAlign: `top`
  },
  link: {
    boxShadow: "none",
    textDecoration: `none`,
  },
  icon: {
    fontSize: 40,
  },

}))

export default AboutPage
